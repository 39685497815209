/*
	- Color palette: https://www.pinterest.co.uk/pin/816699713654534474/
*/

body {
  margin: 0;
  background-color: #a4508b;
  background-image: linear-gradient(326deg, #3c1361 0%, #7c5295 75%);
  font-family: 'Trebuchet MS', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: 100vw;
  overflow-x: hidden;
  -khtml-user-select: none;
  -moz-user-select: none;
  letter-spacing: 1px;
  color: #f4ecd6;
  text-align: center;
}

.promptContainer {
  padding-top: 2%;
}

.prompt {
  font-weight: 300;
}

.hintContainer {
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-block-end: 0;
}
.solutionInstructions {
  padding: 3px 0;
  font-weight: 800;
  font-size: 1.5rem;
  margin-top: min(20px, 3%);
}

.grid {
  display: grid;
  grid-template-columns: max(100px, 8%) repeat(5, max(200px, 16%));
  overflow: scroll;
  gap: 10px 10px;
  grid-auto-flow: row;
  padding: 1% 5%;
  grid-template-rows: auto;
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer, Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.grid::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.selection {
  list-style-type: none;
  border-radius: 10px;
  padding: 3px 0;
  border: 1px solid #a7cab1;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 2%;
  margin-bottom: 3%;
}

.notPossible {
  text-decoration: line-through;
  opacity: 0.15;
}

.detail {
  padding: 3px 0;
  font-weight: 300;
}

.detailLeft {
  line-height: 135px;
  padding: 3px 0;
  font-weight: 300;
}

.brick {
  border: 1px solid #88b7b5;
  border-radius: 10px;
  padding: 5px 5px;
  height: fit-content;
}

.brickContainer {
  display: block;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0px;
  height: fit-content;
}

.hint {
  padding: 3px 0;
  font-weight: 300;
  font-size: 1rem;
}

.buttonContainer {
  margin: auto;
  width: 75%;
  text-align: center;
  margin-bottom: 10%;
}

.check {
  background-color: #1d9801;
  color: white;
  padding: 15px 32px;
  font-size: 16px;
  border-radius: 20px;
  font-weight: 800;
  cursor: pointer;
  border-style: none;
}

.solutionHelp {
  font-weight: 550;
  margin-top: 10px;
  font-size: 200%;
}
